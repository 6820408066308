import React, { Component } from "react";
import Mailchimp from 'react-mailchimp-form';

// a basic form
const CustomForm = ({ status, message, onValidated }) => {

  return (
    <div className="dflex py-3">
<Mailchimp
  action='https://tech.us20.list-manage.com/subscribe/post?u=d454f63cc9f49ba8caa3b7922&amp;id=9359be3b06&amp;f_id=002162e7f0'

  //Adding multiple fields:
  fields={[
    {
      name: 'EMAIL',
      placeholder: 'Email*',
      type: 'email',
      required: true,
      className:"input-email"
    },
  ]}
  // Change predetermined language
  messages = {
    {
      success: "Thank you for subscribing!",
      error: "An unexpected internal error has occurred.",
      //empty: "You must write an e-mail.",
      empty: "All fields are required.",
      duplicate: "Too many subscribe attempts for this email address",
      button: "SUBSCRIBE"
    }
  }
  className='mailchimp-btn dflex w-100'

/>
   </div>
  );
};

class MailChimpForm extends Component {
  render() {
    const url =
      "https://jster.us7.list-manage.com/subscribe/post?u=ed40c0084a0c5ba31b3365d65&id=ec6f32bf5e";
    return (
      <div>
       <CustomForm/>
      </div>
    );
  }
}
export default MailChimpForm;