import React, { PureComponent } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { GetApiDocs } from "../../apis/home-api";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MailChimpForm from "./MailChimp";
import $ from "jquery";

class LandingPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      formData: {},
      sender_name: "",
      focus: false,
      demo:false
    };
    this.handleChange = this.handleChange.bind(this);
    AOS.init({
      once: true
    });
  }


  refreshPage() {
    window.location.reload(false);
  }


  handleChange(e) {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  }
   
 
  
  render() {
    return (
      <>
        <div className="home-page-conatiner landing-page-main-class">
          <div className="landing-page-conatiner">
            <section className="section-1 gradient-bg" >
              <div className="container container-xl-ps pt-50 pb-50" >
                <div className="row">
                  <div className="col-lg-7 col-sm-12 pt-110 text-sm1-center ">
                    <div className="max-width " >
                    {/*<p className="font-color-grey font-size-17 font-weight-500 title-heading">ANTI-MONEY LAUNDERING</p> */}
                    <h1 className="Early-Access-Release" data-aos="fade-up" data-aos-duration="1250">
                    Transact Comply 
                    </h1>
                    <p className=" section-1-title mt-2 mb-4" data-aos="fade-up" data-aos-duration="1250">
                    Dynamic Scenarios and Rule Library Powered Transaction Monitoring Platform For Enterprises
                    </p>
                    {/* <div className="early-h font-size-22 font-weight-bold">
                      Early Access
                    </div> */}
                     <button
                      className="btn btn-primary sm-primary sm-primary-1 Sign-up-for-Free-3 my-3 mr-4"
                      onClick={() => {
                        $("#zsiq_agtpic").click();
                      }}
                    >
                     Book a Discovery Call 
                    </button>
                    <button
                      className="btn btn-primary sm-primary sm-primary-1 Sign-up-for-Free-2 my-3"
                      onClick={()=>{ window.open(process.env.REACT_APP_KEYCLOAK_SIGNUP_URL, "_blank")}}
                    >
                     Sign Up For Free
                    </button>
                    </div>
                    </div>
                  <div className="col-lg-5 col-sm-12 img-div  d-flex align-tems-center ">
                    {/* <img className="screenviewimg"
                      src={require("../../assets/Landing page icons/PrescreeningDashboard.png")}
                    />
                      <img className="relativeimg"
                      src={require("../../assets/Landing page icons/BEARD10.png")}
                    />
                    <img className="relativeimg2"
                      src={require("../../assets/Landing page icons/BEARD07.png")}
                    /> */}
                  <object data={require("../../assets/Landing page icons/Vector 001 Animated.svg")}></object>
                  </div>
                </div>
              </div>
            </section>


            <section className="section-2 purple-background" >
              <div className="container lp-main-container pt-80 pb-80"  data-aos="fade-up" data-aos-duration="1250">
                <div className="row">
                  <div className="purpose w-100">
                    <h1 className="Made-For-a-Purpose font-fff">Features</h1>
                  </div>
                </div>
                <div className="row pt-50">
                  <div className="col-lg-4 col-md-4 mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="purpose-sm">
                      <img
                        src={require("../../assets/Landing page icons/1.png")}
                      ></img>
                      <div className="purpose-heading font-size-14 font-fff">
                      Highly customized Rule Engine – Rule Flexibility
                      </div>
                      <div className="purpose-grey-text font-size-12 font-fff">
                      Automate the monitoring with configurable rules. Set up threshold and configure rules according to the business needs
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="purpose-sm">
                    <img
                        src={require("../../assets/Landing page icons/2.png")}
                      />
                      <div className="purpose-heading font-size-14 font-fff">
                      Simulation - Rules and Thresholds
                      </div>
                      <div className="purpose-grey-text font-size-12 font-fff">
                      Experiment, Test, Refine, optimize: Achieve precision through simulations
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="purpose-sm">
                    <img
                        src={require("../../assets/Landing page icons/3.png")}
                      />
                      <div className="purpose-heading font-size-14 font-fff ">
                      Risk Based transaction monitoring
                      </div>
                      <div className="purpose-grey-text font-size-12 font-fff">
                      Elevate Security with Targeted Risk-Based Transaction Monitoring

                      </div>
                    </div>
                  </div>
                </div>
                <div className="row pt-50">
                  <div className="col-lg-4 col-md-4 mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="purpose-sm">
                    <img
                        src={require("../../assets/Landing page icons/4.png")}
                      />
                      <div className="purpose-heading font-size-14 font-fff">
                      Entity and Case Management
                      </div>
                      <div className="purpose-grey-text font-size-12 font-fff">
                      Efficient Entity and Case Management to Streamline Your Compliance Workflow
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="purpose-sm">
                    <img
                        src={require("../../assets/Landing page icons/5.png")}
                      />
                      <div className="purpose-heading font-size-14 font-fff">
                      Automated Reports Generation
                      </div>
                      <div className="purpose-grey-text font-size-12 font-fff">
                      Insightful reports effortlessly to make data-driven decisions
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="purpose-sm">
                    <img
                        src={require("../../assets/Landing page icons/6.png")}
                      />
                      <div className="purpose-heading font-size-14 font-fff">               
                      Sanctions and Watchlist Screening
                      </div>
                      <div className="purpose-grey-text font-size-12 font-fff">
                      Screen individuals and entities against comprehensive data sources, minimizing compliance risks
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="section-2 " >
              <div className="container lp-main-container"  data-aos="fade-up" data-aos-duration="1250">
                <div className="row row-mt pt-50">
                  <div className="purpose w-100">
                    <h1 className="Made-For-a-Purpose">
                    Benefits
                    </h1>
                  </div>
                </div>
                <div className="row row-mt pt-50">
                  <div className="col-lg-4 col-md-4 mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="purpose-sm">
                    <img
                        src={require("../../assets/Landing page icons/01-02.png")}
                      />
                      <div className="purpose-heading  font-size-14">
                      Easy to use
                      </div>
                      <div className="purpose-grey-text font-size-12">
                      Hassle free experience on our user friendly platform, needed no tech ability and self-explanatory UI
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="purpose-sm">
                    <img
                        src={require("../../assets/Landing page icons/02-02.png")}
                      />
                      <div className="purpose-heading  font-size-14">
                      Enhanced Compliance 

                      </div>
                      <div className=" purpose-grey-text font-size-12">
                      Stay ahead of regulatory requirements with automated AML compliance monitoring and reporting
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="purpose-sm">
                    <img
                        src={require("../../assets/Landing page icons/03-02.png")}
                      />
                      <div className="purpose-heading  font-size-14">
                    Customizable Risk Assessment 

                      </div>
                      <div className="purpose-grey-text  font-size-12">
                      Tailor risk assessment algorithms to your specific business needs, ensuring right customer risk profiling
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row row-mt pt-50 pb-80">
                  <div className="col-lg-4 col-md-4 mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="purpose-sm">
                    <img
                        src={require("../../assets/Landing page icons/04-02.png")}
                      />
                      <div className="purpose-heading  font-size-14">
                      Time-Efficient Investigations

                      </div>
                      <div className=" purpose-grey-text font-size-12">
                      Speed up investigations with automated case management, reducing manual effort and increasing efficiency

                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="purpose-sm">
                    <img
                        src={require("../../assets/Landing page icons/05-02.png")}
                      />
                      <div className="purpose-heading  font-size-14">
                      Streamlined Reporting 

                      </div>
                      <div className="purpose-grey-text font-size-12">
                      Automate the creation of Suspicious Activity Reports (SARs) for quicker, more streamlined reporting to regulatory authorities

                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="purpose-sm">
                    <img
                        src={require("../../assets/Landing page icons/06-02.png")}
                      />
                      <div className="purpose-heading  font-size-14">
                      Comprehensive Customer Management

                      </div>
                      <div className="purpose-grey-text font-size-12">
                      Gain a competitive edge by offering a comprehensive customer management system within our transaction monitoring solution
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="section-2 text-justify purple-background">
            <div className="container container-xl-ps" data-aos="fade-up" data-aos-duration="1250">
                <div className="row">
                  <div className="col-lg-6 col-sm-12 img-div text-center d-flex align-tems-center pt-50">
                  <object data={require("../../assets/Landing page icons/Vector 002 animated.svg")}></object>
                  </div>
                  <div className="col-lg-6 col-sm-12 pt-50 ">
                    <div className="max-width px-3" >
                    {/*<p className="font-color-grey font-size-17 font-weight-500 title-heading">ANTI-MONEY LAUNDERING</p> */}
                    <h1 className="fontrandom text-left" >
                       Still have question?<br/><span> Connect </span>with us
                    </h1>
                    <div className="my-3">
                      <div className="d-flex listGroup mb-4">
                        <figure className="mr-4 listImage">
                          <img src={require("../../assets/Dark Blue Arrow.png")}/>
                        </figure>
                        <div className=" text-left">
                          <h5 className="listItem">Explore our Technology and Data Asset Solutions</h5>
                        </div>
                      </div>
                      <div className="d-flex listGroup my-4">
                        <figure className="mr-4 listImage">
                          <img src={require("../../assets/Dark Blue Arrow.png")}/>
                        </figure>
                        <div  className=" text-left">
                          <h5 className="listItem">Get Pricing Information</h5>
                        </div>
                      </div>
                      <div className="d-flex listGroup mt-4">
                        <figure className="mr-4 listImage">
                          <img src={require("../../assets/Dark Blue Arrow.png")}/>
                        </figure>
                        <div  className=" text-left">
                          <h5 className="listItem">Free Trial or Demo</h5>
                        </div>
                      </div>
                    </div>
                    {/* <div className="early-h font-size-22 font-weight-bold">
                      Early Access
                    </div> */}
                     <button
                      className="btn btn-primary sm-primary sm-primary-1 trialButton my-3 mr-2"
                      onClick={() => {
                        $("#zsiq_agtpic").click();
                      }}
                    >
                     Book a Discovery Call 
                    </button>
                    <button
                      className="btn btn-primary sm-primary sm-primary-1 Sign-up-for-Free-3 my-3"
                      onClick={()=>{ window.open(process.env.REACT_APP_KEYCLOAK_SIGNUP_URL, "_blank")}}
                    >
                     Sign Up For Free
                    </button>
                    {/* <button
                      className="btn btn-primary sm-primary sm-primary-1 Sign-up-for-Free my-3"
                      onClick={()=>{this.props.props.openBot(true)}}
                    >
                     Book a Free Demo 
                    </button> */}
                    </div>
                    </div>
                </div>
              </div>
            </section>

{/*  

            <section className="section-2" id="Navcontact">
              <div className="container lp-main-container">
                <div className="row row-mt pt-80 pb-80">
                  <div className="col-lg-12 col-md-12 mb-4 mb-md-0 col-wd-2 text-center">
                    <h1 className="Made-For-a-Purpose">Contact Us</h1>
                  </div>
                </div>
                <div className="row row-mt pt-50">
                  <Contact demo={this.state.demo} pageName="contact-us" />
                </div>
              </div>
            </section> */}
                <section className="p-0">
              <div className="section-grey" data-aos="fade-up" data-aos-duration="2000" data-aos-once="true" data-aos-offset="300">
                <div className="container container-xl-ps">
                <div className="row">
                  <div className="col-md-5 col-sm-12">
                    <h2 className="WeeklyNewsletter">Subscribe to our weekly newsletter</h2>
                 </div>
                 <div className="col-md-7 col-sm-12">
                   <MailChimpForm/>
                 </div>
                 </div>
              </div>
              </div>
            </section>
          </div>
        </div>
      </>
    );
  }
}

export default LandingPage;
